.checkout-onepage-success{
  h1,h2,h3,h4,h5{
    text-transform: none;
  }
  .thankyou-wrapper{
    max-width: 900px;
    margin:0 auto;
    .block.newsletter{
      margin: 10px 0;
    }
    .newsletter-checkout-wrapper{
      display: none;
    }
    .order-info{
      display: flex;
      flex-wrap: wrap;
      > div{
        width: 50%;
        padding: 0 20px;
        @media only screen and (max-width: 767px){
          width: 100%;
        }
      }
    }
    .product-grid{
      h3{
        text-align: left;
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 30px;
      }
    }
    .customer-info{
      h3{
        text-align: left;
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 30px;
      }
      .actions-toolbar{
        margin-top: 30px;
        @media only screen and (max-width:767px){
          display: none;
        }
        .primary{
          width: 100%;
          .action.primary{
            padding: 15px;
          }
        }
      }
    }
    .address-wrapper{
      .shipping-address{
        text-align: left !important;
      }
    }
    .shipping-method{
      text-align: left !important;
    }
    .order-wrapper,
    .info-wrapper{
      display: flex;
      flex-wrap: wrap;
      .column{
        width: 50%;
        text-align: left;
        &:last-child{
          h4,p{
            padding-left: 10px;
          }
        }
      }
    }
    .order-items{
      .order-item-row{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;
        @media only screen and (max-width: 767px){
          margin-bottom: 15px;
        }
        > .col{
          width: 110px;
          &:last-child{
            flex: 1;
            display: flex;
            flex-wrap: wrap;
            .innerCol{
              @media only screen and (max-width: 767px){
                width: 100%;
                padding-left: 20px;
              }
              &:first-child{
                flex: 1;
                text-align: left;
                padding-left: 20px;
                .col{
                  &.sku{
                    margin-top: 5px;
                  }
                  &.qty{
                    margin-top: 5px;
                    text-align: left !important;
                  }
                }
              }
            }
          }
        }
      }
    }
    .order-info{
      .product-grid{
        @media only screen and (max-width: 767px){
          margin-top: 30px;
        }
        .actions-toolbar{
          display: none;
          .action.primary{
            padding: 15px;
          }
          @media only screen and (max-width: 767px){
            display: block;
          }
        }
        .order-items{
          .table-order-items{
            tfoot{
              tr{
                th.textalign-right{
                  text-align: left !important;
                }
                &.grand_total{
                  font-size: 20px;
                }
              }
            }
          }
        }
      }
    }
  }
}
