.page-footer{
  background-color: #254f9c !important;
}
.footer{
  .subBlock{
    background: #fff;
    padding-bottom: 70px;
    padding-top: 70px;
    position: relative;
    &:after{
      content: '';
      display: block;
      width: calc(100% - 30px);
      position: absolute;
      top:0;
      left: 50%;
      transform: translateX(-50%);
      border-top: 1px solid #ccc;
      max-width: 1801px;
      @media only screen and (max-width: 766px){
        width: 100%
      }
    }
    .row{
      display: flex;
      flex-wrap: wrap;
      color: #303030 !important;
      @media only screen and (max-width: 767px) {
        margin: 0 !important;
      }
      .c-column{
        width: 50%;
        padding:0 15px;
        @media only screen and (max-width: 500px) {
          width: 100%;
          margin-bottom: 10px;
        }
        > h4{
          text-align: center;
          margin: 10px 0 20px 0;
          font-size: 20px;
          font-weight: 600;
          color: #303030 !important;
        }
        p{
          color: #303030 !important;
        }
        .c-inner{
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          justify-content: center;
          img{
            height: 50px;
          }
          &.social{
            a{
              margin:0 15px;
              &:hover{
                img{
                  filter: invert(28%) sepia(30%) saturate(2613%) hue-rotate(195deg) brightness(89%) contrast(94%);
                }
              }
              img{
                transition: 0.3s ease-in-out;
                margin:0;
              }
            }
          }
        }
      }
    }
  }
  .columnContainer{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px !important;
    margin-top:30px !important;
    max-width: 835px !important;
    @media only screen and (max-width: 767px) {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
    .c-column{
      width: 50%;
      padding:0 15px;
      @media only screen and (max-width: 500px) {
        width: 100%;
        margin-bottom: 10px;
      }
      > h4{
        color: #fff !important;
        text-align: center;
        margin: 10px 0 0 0;
      }
      .c-inner{
        justify-content: center;
        display: flex;
        flex-wrap: wrap;
        img{
          height: 40px;
          margin:0 10px;
        }
      }
    }
  }
  .brandTitle{
    background: #fff;
    margin: 0;
    padding:0 5px 20px 5px;
    text-align: center;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 30px;
    font-weight: bold;
  }
  .pre-footer{
    .pre-footer-content{
      margin-left: -10px;
      margin-right: -10px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .brand-item{
        margin:10px auto;
        padding:0 10px;
        img{
          max-width: 100%;
        }
      }
    }
  }
  .footer-v3{
    background-color: #254f9c !important;
    .col-md-12{
      float: none !important;
    }
    ul{
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right:0 !important;
      @media only screen and (max-width: 991px) {
        flex-wrap: wrap;
      }
      li{
        padding:0 30px;
        position: relative;
        text-align: center;
        @media only screen and (max-width: 991px) {
          margin-bottom: 10px;
        }
        @media only screen and (max-width: 500px) {
          width: 100%;
        }
        a{
          color: #fff !important;
          font-size: 24px;
          @media only screen and (max-width: 1800px) {
            font-size: 19px;
          }
          @media only screen and (max-width: 1366px) {
            font-size: 16px;
          }
        }
        &:last-child{
          &:after{
            display: none;
          }
        }
        &:after{
          content:'';
          display: block;
          height: 60%;
          width: 2px;
          background: #fff;
          position: absolute;
          top:50%;
          transform: translateY(-50%);
          right:0;
          @media only screen and (max-width: 500px) {
            display: none;
          }
        }
      }
    }
    .cards{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      img{
        height: 40px;
        margin:0 10px;
      }
    }
  }
  .newsletter-subscribe{
    .form-group{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 0;
      @media only screen and (max-width: 500px) {
        flex-wrap:wrap;
        input{
          width: 100%;
        }
      }
      input{
        width: auto;
        max-width: none;
        padding: 10px;
        height: 45px;
        text-align: center;
        font-size: 20px;
        @media only screen and (max-width: 767px){
          width: 100%;
          font-size: 16px;
          flex: 1;
          text-align: left;
        }
        &::placeholder {
          color: #ababab;
          opacity: 1;
        }
        &:-ms-input-placeholder {
          color: #ababab;
        }
        &::-ms-input-placeholder {
          color: #ababab;
        }
      }
      button{
        margin-left:0;
        margin:0;
        font-size: 20px;
        &:hover{
          background-color: #bb7e00;
          border: #bb7e00 1px solid;
        }
        @media only screen and (max-width: 767px){
          font-size: 16px;
          width: 100%;
        }
      }
      h3{
        width: 100%;
        text-align: center;
        font-size: 17px;
        @media only screen and (min-width:768px){
          display: none;
        }
      }
      .newsletterInfo{
        width: 100%;
        text-align: center;
        margin-top: 10px;
        @media only screen and (max-width: 767px){
          text-align: left;
        }
        p{
          &.small{
            font-size: 11px;
          }
        }
        a{
          color: #303030 !important;
          text-decoration: underline;
        }
      }
    }
  }
}
