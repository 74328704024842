.catalogsearch-result-index{
    .sidebar{
        display: none;
    }
    #layered-horizontal-container{
        margin-bottom: 0;
    }
    #layered-filter-block{
        .block-content.filter-content{
             .filter-options{
                 .filter-options-item{
                    .filter-options-title{
                        border: 0;
                    }
                 }
             }
        }
    }
}
