.category-section{
  display: flex;
  flex-wrap: wrap;
  h3{
    width: 100%;
    @media only screen and (min-width: 768px){
      padding: 0 15px;
    }
  }
  .catItem{
    padding:0 15px;
    width: 25%;
    margin-bottom: 15px;
    @media only screen and (max-width: 991px){
      width: 33.333%;
    }
    @media only screen and (max-width: 767px){
      width: 50%;
    }
    @media only screen and (max-width: 500px){
      width: 100%;
    }
    .category-product{
      position: relative;
      .img-holder{
        background-size:cover;
        padding-bottom: 100%;
      }
      .catInfo{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: rgba(0, 0, 0, 0.4);
        text-align: center;
        display: block;
        .buttonLook{
          color: #fff;
          background: #283583;
          padding: 8px 20px;
          display: inline-block;
          margin-bottom: 5px;
          font-size: 19px;
          font-weight: bold;
          @media only screen and (max-width: 991px){
            font-size: 17px;
          }
        }
        h4{
          color: #fff;
          font-weight: bold;
          font-size: 21px;
          text-transform: none;
          display: block;
          margin-bottom: 3px;
          margin-top:0;
          line-height: 30px;
          @media only screen and (max-width: 991px){
            font-size: 19px;
            line-height: 27px;
          }
        }
        > div{
          align-self: center;
          width: 100%;
        }
      }
    }
  }
}
