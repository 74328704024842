.catalog-product-view{
  &.theme-pearl{
    &.product-page-v4{
      .info.detailed{
        > .items{
          > .item.title{
            > a{
              &.switch{
                background-color: #ddd !important;
                &:hover{
                  background-color: #ddd !important;
                }
              }
              font-size: 1.8em;
              @media only screen and (max-width: 767px){
                font-size: 1.4rem;
              }
            }
          }
        }
        .item.content{
          background-color: #ddd !important;
          font-size: 1.6em;
          @media only screen and (max-width: 767px){
            font-size: 1.4rem;
          }
        }
      }
      .owl-nav,.owl-dots{
        &.disabled{
          display: none;
        }
      }
      .lb-outerContainer{
        .lb-nav{
          top:37px;
        }
        .lb-data{
          .lb-number{
            padding: 0;
            margin: 5px 0;
          }
        }
      }
      #lightbox {
        .lb-container{
          background: #fff;
        }
        .lb-dataContainer {
          background-color: #fff;
          border-bottom: 1px solid #ccc;

          .lb-data {
            width: 100%;

            .lb-details {
              width: initial;
              float: initial;
              text-align: center;
              line-height: 1.1em;
              color: black;
              display: inline-block;
              vertical-align: top;
            }

            .lb-closeContainer {
              display: inline-block;
              float: right;
            }
          }
        }
      }
      .columns{
        @media only screen and (max-width:1800px){
          padding-left: 15px;
          padding-right:15px;
        }
        @media only screen and (min-width:768px){
          max-width: 1800px !important;
        }
      }
      .product-top-main{
        display: flex;
        flex-wrap:wrap;
        padding-top:50px;
        .product{
          &.media{
            flex:1;
            max-width: 1100px;
            min-width: 0;
            @media only screen and (max-width: 767px){
              max-width: none;
              width: 100%;
              flex:none;
            }
            &:before{
              display: none;
            }
          }
        }
        .product-info-main{
          margin-left:50px;
          flex:1;
          min-width: 400px;
          position: relative;
          left: auto;
          right: auto !important;
          top: auto;
          padding-top: 0;
          @media only screen and (max-width: 767px){
            min-width: 0;
            width: 100%;
            flex:none;
            margin-left:0;
          }
          .product-add-form{
            padding-top: 0 !important;
            .box-tocart{
                .fieldset {
                    .field {
                        margin: 0;
                    }
                }
              .control{
                position: relative;
                margin-right:10px;
                width: 112px;
                text-align: center;
                input#qty{
                  margin-right:0;
                }
                .qty-changer{
                  .qty_inc{
                    height: 50px;
                    border: 1px solid #c2c2c2;
                    line-height: 48px;
                    position: absolute;
                    top:0;
                    right:0;
                    width: 30px;
                    font-size: 22px;
                  }
                  .qty_dec{
                    height: 50px;
                    line-height: 48px;
                    border: 1px solid #c2c2c2;
                    position: absolute;
                    top:0;
                    left:0;
                    width: 30px;
                    font-size: 22px;
                  }
                }
                .mage-error{
                  &:not(.qty){
                    position: absolute;
                    bottom: -20px;
                    width: 400px;
                    left:0;
                    //todo: change the error qty, temp solution
                  }
                }
              }
              .actions{
                  button {
                      margin-bottom:0;
                  }
                @media only screen and (max-width:767px){
                  width: calc(100% - 122px);
                }
              }
              .extrainfo {
                  display: flex;
                  flex-direction: column;
                  width: 100%;
                  color:red;
                  em {
                      &:after {
                          content: ' ';

                      }
                  }

                  span {
                      margin-top: 5px;
                  }
              }
            }
          }
            .product-info-price{
                display: flex;
                align-items: center;
                position: relative;
            }
            .product.attribute.overview {
                .value {
                    font-size: 1.6em;
                }
            }
            .product-info-stock-sku{
                order:2;
                margin-left:auto;
              .product.attribute.sku{
                .type{
                  &:after{
                    content:':';
                  }
                }
              }
            }
            .price-box {
                @media only screen and (max-width:767px){
                    padding-bottom:45px;
                }
            }
            .packaging,
            .individual_price {
                display: block;
                @media only screen and (max-width:767px){
                    position: absolute;
                    bottom: 20px;
                    left: 0;
                }
                @media only screen and (max-width:639px){
                    padding-left: 10px;
                }
            }

            .individual_price {
                padding-bottom: 10px;
                @media only screen and (max-width:767px){
                    bottom: 10px;
                    height: 15px;
                }
            }
          .product-social-links{
            .tocompare{
              &:before{
                font-size: 21px !important;
                width: 22px !important;
                height: 24px;
                line-height: 19px !important;
              }
              span{
                font-size: 15px;
              }
            }
          }
        }
      }
      .table-wrapper{
        &.grouped{
          tbody{
            td.item{
              .price{
                font-size: 20px;
              }
            }
          }
        }
      }
    }

    .product-image{
      .owl-dots{
        text-align: center;
        @media only screen and (min-width: 768px){
          display: none;
        }
        button{
          background: none;
          border: 0;
          padding: 0;
          &.active{
            background: none;
            border: 0;
          }
        }
      }
      .owl-nav{
        > button{
          margin: 0;
          left: 0;
          top: 0;
          height: 100%;
          width: 60px;
          background: rgba(255, 255, 255, 0.8) !important;
          font-size: 0;
          text-indent: 0;
          transition: all 0.3s ease-in-out;
          visibility: hidden;
          opacity: 0;
          position: absolute;
          border:none;
          > span{
            display: none;
          }
          &:after{
            content: '';
            display: block;
            width: 40px;
            height: 40px;
            background-position: -72px -71px;
            background-size: 200px;
            background-image: url(../images/gallery.png) !important;
            background-repeat: no-repeat;
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
          }
          &.owl-next{
            left:auto;
            right: 0;
            &:after{
              background-position: -130px -71px;
            }
          }
        }
      }
      &:hover{
        .owl-next,.owl-prev{
          visibility: visible;
          opacity:1;
        }
      }
      .owl-item{
        a{
          display: block;
          padding-bottom: 100%;

        }
        position: relative;
        img{
          height: auto;
          left: 50%;
          max-height: 100%;
          max-width: 100%;
          position: absolute;
          top: 50%;
          transform: translate3d(-50%, -50%, 0);
          transition-duration: .3s;
          transition-property: width,height,top,left;
          vertical-align: middle;
          width: auto;
        }
      }
    }
    .thumbnails{
      @media only screen and (max-width:767px){
        display: none;
      }
      .owl-nav{
        > button{
          margin: 0;
          left: 0;
          top: 0;
          height: 100%;
          background: rgba(255, 255, 255, 0.8) !important;
          font-size: 0;
          text-indent: 0;
          transition: all 0.3s ease-in-out;
          visibility: hidden;
          opacity: 0;
          width: 52px;
          position: absolute;
          border:none;
          > span{
            display: none;
          }
          &:after{
            content: '';
            display: block;
            width: 40px;
            height: 40px;
            background-position: -72px -71px;
            background-size: 200px;
            background-image: url(../images/gallery.png) !important;
            background-repeat: no-repeat;
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
          }
          &.owl-next{
            left:auto;
            right: 0;
            &:after{
              background-position: -130px -71px;
            }
          }
        }
      }
      .owl-next,.owl-prev{
        width: 52px;
      }
      &:hover{
        .owl-next,.owl-prev{
          visibility: visible;
          opacity:1;
        }
      }
      .owl-item{
        height: 90px;
        position: relative;
        border: 1px solid transparent;
        img{
          height: auto;
          left: 50%;
          max-height: 100%;
          max-width: 100%;
          position: absolute;
          top: 50%;
          transform: translate3d(-50%, -50%, 0);
          transition-duration: .3s;
          transition-property: width,height,top,left;
          vertical-align: middle;
          width: auto;
        }
        &.activeDisplay{
          border: 1px solid #ff5501;
        }
      }
    }
    table.grouped{
      .item{
        .product-item-name{
          width: 100%;
        }
        .price-box,
        .packaging-details{
          display: inline-block;
          vertical-align: middle;
        }
        .packaging-details{
          margin-left: 10px;
          .individual_price{
            padding-bottom: 0;
          }
          @media only screen and (max-width: 767px) {
            .packaging,
            .individual_price{
              position: relative;
              bottom: auto;
              left: auto;
              height: auto;
            }
          }
        }
      }
    }
  }
  #product_stock_alert_container{
    .stockalert{
      .title{
        display: flex;
        width: 100%;
        margin-bottom: 5px;
      }
    }
    .bss_productstockaler{
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      .field{
        flex: 1;
        input{
          height: 34px;
        }
      }
    }
  }
}
