.store-banner{
  position: relative;
  overflow: hidden;
  .owl-nav{
    display: none;
  }
  .owl-dots{
    text-align: center;
    button{
      background: transparent !important;
      padding: 0 !important;
      border: 0 !important;
    }
  }
  .owl-item{
    @media only screen and (max-width: 767px){
      height: 350px;
      position: relative;
      overflow: hidden;
    }
  }
  .mobileImage{
    display: none !important;
    @media only screen and (max-width: 767px){
      position: absolute;
      min-width: 100%;
      min-height: 100%;
      max-height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: auto;
      height: auto;
      max-width: none;
      display: block !important;
    }
  }
  .store-container{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height:100%;
    &.overlay{
      background: rgba(0,0,0,0.4);
    }
    .store-box{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      left: 0;
      padding: 0 10px;
      max-width: 475px;
      margin-left: 140px;
      @media only screen and (max-width:991px){
        margin-left: 0;
        left:50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  img{
    width: 100%;
  }
  @media only screen and (max-width: $boot-lg-break) {
    text-align: center;
  }

  &:after{
    content:'';
    display:block;
    clear:both;
  }
  .store-container{
    float:left;
    @media only screen and (max-width: $boot-lg-break){
      float:none;
      display:inline-block;
      text-align: center;
    }
    .store-box{
      .store-title{
        color:#fff;
        display: block;
        margin-bottom:18px;
        font-weight: bold;
        font-size: 50px;
        line-height: 53px;
        text-shadow: 1px 0px 3px rgba(0,0,0,0.2);
        @media only screen and (max-width: $boot-sm-break){
          font-size:24px;
          line-height: 30px;
          margin-bottom:4px;
        }
      }
      .store-text{
        font-size:24px;
        color:#fff;
        display: block;
        line-height:26px;
        font-weight: bold;
        text-shadow: 1px 0px 3px rgba(0,0,0,0.2);
        @media only screen and (max-width: $boot-sm-break){
          font-size:19px;
          line-height: 23px;
        }
      }
    }
  }
  > img{
    position: absolute;
    top: -9999px;
    right: -9999px;
    bottom: -9999px;
    left: -9999px;
    margin: auto;
    min-width: 100%;
    min-height: 100%;
    z-index: -1;
  }
}
