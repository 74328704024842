.checkout-index-index{
  ul#ui-id-1 {
      width: inherit;
  }
  .header{
    .actions{
      .customerLink{
        margin-right:0;
      }
      .block-search{
        @media only screen and (min-width: 992px){
          margin-right:0 !important;
        }
        @media only screen and (max-width: 767px){
          margin-right:30px !important;
        }
      }
    }
  }
  .nav-sections{
    display: block;
  }
  .columns{
    padding-top: 40px !important;
    @media only screen and (min-width: 768px){
      padding: 0 15px;
    }
    .iosc-place-order-container{
      .iosc-place-order-button.checkout{
        background:none;
        background-color:#254f9c;
        transition: all .3s ease-in-out;
        border:none;
        &:hover{
          background-color:#bb7e00;
          color:#fff !important;
          border:none;
        }
      }
    }
    .newaddress-save-button{
      background-color:#254f9c;
      border:none;
      &:hover{
        background-color:#bb7e00;
        color:#fff !important;
        border:none;
      }
    }
    .title-number{
      color:#254f9c;
      border-color:#254f9c;
      background-image:none;
      box-shadow:none;
    }
    #opc-shipping_method{
      .table-checkout-shipping-method{
        thead{
          th{
            vertical-align: top;
            text-align: center;
            &.col-method{
              &:first-child{
                text-indent: -9999px;
              }
            }
          }
        }
        tbody{
          td{
            text-align: center;
            vertical-align: top;
            padding-bottom: 15px;
            padding-top: 15px;
          }
        }
      }
    }
    #checkout-payment-method-load{
      .payment-method{
        input.radio{
          vertical-align: middle;
          margin-top: 0;
        }
      }
    }
  }
  .opc-wrapper{
    .shipping-address-item{
      &.selected-item{
        border-color: #254f9c;
        &:after{
          background: #254f9c;
        }
      }
    }
    .shipping-address-items{
      display: flex;
      flex-wrap: wrap;
      .shipping-address-item{
        width: 50% !important;
      }
    }
  }
  .qtybuttons{
    a{
      &.add,&.remove{
        &:before{
          color: #254f9c;
        }
        &:hover{
          &:before{
            color:#bb7e00;
          }
        }
      }
    }
  }
  .notice{
    color: red;
    font-size: 12px;
    float: left;
    width: 100%;
  }
}
