.products{
  &.products-related, &.products-crosssell, &.products-upsell{
    .product-item{
      .minimal-price{
        .price-label{
          display: block !important;
        }
      }
      .product-item-name{
        .product-item-link{
          font-size: 16px !important;
          font-weight: bold;
        }
      }
      .field.choice.related{
        width: auto !important;
        label{
          span{
            font-size: 15px;
          }
        }
      }
      .product-item-actions{
        margin-top:0 !important;
      }
    }
  }
  &.list{
    .minimal-price{
      .price-label{
        display: block !important;
      }
    }
    .item{
      //border:1px solid #000;
      position: relative;
      &:after{
        content:'';
        display: block;
        border-bottom:1px dotted #ccc;
        position: absolute;
        left: 0;
        bottom: -10px;
        width: 100%;
      }
      .product-item-actions{
        text-align: center;
        .actions-secondary{
          display: none;
        }
      }
      .weltpixel-quickview{
        display: none !important;
      }
      .stock{
        &.unavailable{
          font-size: 12px;
          margin-bottom: 5px;
          padding: 0;
        }
      }
      a.action{
        background: #254f9c !important;
        &:hover{
          background: #bb7e00 !important;
        }
      }
    }
  }
}


.page-products{
  .grid{
    &.products-grid{
      .product-items{
        display: flex;
        flex-wrap: wrap;
        .product-item{
          margin: 0 10px 10px 10px !important;
          width: calc(20% - 20px) !important;
          @media only screen and (max-width: 991px){
            width: calc(33.333% - 20px) !important;
          }
          @media only screen and (max-width: 767px){
            width: calc(50% - 20px) !important;
          }
          @media only screen and (max-width: 500px){
            width: calc(100% - 20px) !important;
          }
          .product-item-info:not(:hover){
            .product-item-inner{
              width: 0;
              overflow: hidden;
            }
            .tocart{
              @media only screen and (min-width: 767px){
                border: 1px solid transparent;
                width: 0;
                background: none;
                transition: none;
              }
        }
      }
          .minimal-price{
            .price-label{
              display: block !important;
            }
          }
          .actions-primary{
            .tocart{
              font-size: 13px;
              text-transform: uppercase;
              border:2px solid #254f9c;
              padding: 0;
              display: flex;
              align-items: center;
              margin: 0 auto;
              &:hover{
                background: #254f9c;
                > span{
                  background: #bb7e00;
                  color: #fff;
                }
              }
              &:before{
                content: '';
                background-image: url("../images/shopping-cart-solid.svg");
                padding: 14px 22px;
                background-size: 18px;
                background-repeat: no-repeat;
                background-position: center;
                display: inline-block;
              }
              > span{
                background: #fff;
                color:#bb7e00;
                padding: 7px 12px;
                display: inline-block;
                font-size: 15px;
                font-weight: bold;
              }
            }
          }
          .product-item-info{
            .tocart{
              transition:none;
            }
          }
      .product-item-inner{
        height: auto !important;
        @media only screen and (max-width: 767px) {
          position: relative;
          width: auto;
          top: auto;
        }
      }
    }
  }
}
}
.products-list{
  .products{
    .product{
      .actions-primary{
        .tocart{
          font-size: 13px;
          text-transform: uppercase;
          border:2px solid #254f9c;
          padding: 0;
          display: flex;
          align-items: center;
          &:hover{
            background: #254f9c;
            > span{
              background: #bb7e00;
              color: #fff;
            }
          }
          &:before{
            content: '';
            background-image: url("../images/shopping-cart-solid.svg");
            padding: 14px 22px;
            background-size: 18px;
            background-repeat: no-repeat;
            background-position: center;
            display: inline-block;
          }
          > span{
            background: #fff;
            color:#bb7e00;
            padding: 7px 12px;
            display: inline-block;
            font-size: 15px;
            font-weight: bold;
          }
        }
      }
    }
  }
}
}

.block-products-list{
  h2{
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 30px;
    font-weight: bold;
    @media only screen and (max-width: 767px) {
      font-size: 25px;
    }
  }
}

.widget-product-carousel.product-items{
    margin-bottom: 50px;
  .product-item-inner{
    .product-item-actions{
        .actions-primary {
            display: block;
            margin: 0 auto;
        }
        .actions-secondary {
            display: none;
        }
    }
  }
}

.widget-product-grid.product-items{
    .product-item-details{
        .product-item-link{
            font-size: 20px;
        }
    }
}


.products-grid{
  .product-item{
    .product-item-name{
      a{
        font-size: 20px !important;
      }
    }
    .packaging-details{
      margin-bottom: 20px;
      text-align: center;
      .packaging{
        font-size: 15px;
      }
      .individual_price{
        display: none;
      }
    }
    .price-box{
      margin: 10px 0 8px;
    }
  }
}
