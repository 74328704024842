.page-header .panel.wrapper {
  display: none !important;
}
@media only screen and (max-width: 768px) {
  .page-header .header > .row {
    margin: 0;
  }
}
@media only screen and (min-width: 1401px) {
  .page-header .header.content {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}

@media only screen and (min-width: 768px) {
  :root .theme-pearl .page-wrapper .page-header .logo img {
    height: auto;
    width: auto;
  }
  :root .page-header-v3 .navigation .level0 > .level-top, :root .page-header-v4 .navigation .level0 > .level-top {
    line-height: 44px;
  }
}
.checkout-index-index .page-wrapper .page-header .header .nav-sections {
  display: block;
}
.checkout-index-index .page-wrapper .page-header .header .nav-sections #ui-id-1 {
  background: none !important;
}

.page-wrapper .page-header {
  background: none !important;
}

.mobile-nav .nav-sections .nav-sections-items #store\.links, .mobile-nav .nav-sections .nav-sections-items #store\.menu {
  display: block !important;
}
.mobile-nav .nav-sections .nav-sections-items #store\.links .header.links, .mobile-nav .nav-sections .nav-sections-items #store\.menu .header.links {
  display: block !important;
}
.mobile-nav .nav-sections .nav-sections-items .nav-sections-item-title.section-item-title:first-child:not(.active) ~ #store\.menu {
  display: none !important;
}
.mobile-nav .nav-sections .nav-sections-items .nav-sections-item-title.section-item-title:nth-child(3):not(.active) ~ #store\.links {
  display: none !important;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mobile-nav .nav-sections .nav-sections-items .nav-sections-item-title.section-item-title:not(.active) {
    display: block !important;
  }
}
.mobile-nav .nav-sections .navigation .level0 {
  width: 100%;
}

.customerLink {
  width: 35px;
  height: 35px;
  border: 1px solid #254f9c;
  border-radius: 50%;
  position: relative;
  margin: 0 15px;
  padding: 0 16.5px;
}
@media only screen and (max-width: 991px) {
  .customerLink {
    display: none;
  }
}
.customerLink img {
  width: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .nav-sections .navigatation .level0 {
    width: 100%;
  }
}
@media only screen and (max-width: 991px) {
  .nav-sections .customer-welcome + .authorization-link {
    display: block;
  }
}

.customHeader .stickyPlaceholder {
  height: 0;
}
.customHeader .row {
  padding: 0 15px;
}
.customHeader .topHeader .topHeaderWrap {
  display: flex;
  position: relative;
  padding: 20px 0;
  align-items: center;
  z-index: 99;
}
.customHeader .topHeader .logoWrap {
  width: 300px;
}
.customHeader .topHeader .logoWrap .logo {
  max-width: 100% !important;
}
@media only screen and (max-width: 767px) {
  .customHeader .topHeader .logoWrap .logo img {
    width: 115px !important;
    height: auto !important;
  }
}
@media only screen and (max-width: 767px) {
  .customHeader .topHeader .logoWrap .logo {
    display: block;
    margin: 0 !important;
  }
}
.customHeader .topHeader .logoWrap .nav-toggle {
  position: absolute !important;
  right: 0;
  left: auto;
  top: 45px !important;
}
@media only screen and (max-width: 991px) {
  .customHeader .topHeader .logoWrap .nav-toggle {
    display: block;
  }
}
@media only screen and (max-width: 767px) {
  .customHeader .topHeader .logoWrap .nav-toggle {
    top: 19px !important;
  }
}
.customHeader .topHeader .logoWrap .nav-toggle:before {
  color: #254f9c;
}
.customHeader .topHeader .logoWrap img {
  transition: 0.3s ease-in-out;
}
.customHeader .topHeader .logoWrap .logo {
  float: none;
  margin: 0;
  display: block;
}
.customHeader .topHeader .infoColumn {
  flex: 1;
}
.customHeader .topHeader .infoColumn .convLinks {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
@media only screen and (max-width: 991px) {
  .customHeader .topHeader .infoColumn .convLinks {
    display: none;
  }
}
.customHeader .topHeader .infoColumn .convLinks a {
  margin: 0 20px;
  position: relative;
  color: #254f9c;
  text-transform: none;
  font-size: 18px;
}
@media only screen and (max-width: 1200px) {
  .customHeader .topHeader .infoColumn .convLinks a {
    font-size: 15px;
  }
}
.customHeader .topHeader .infoColumn .convLinks a:first-child {
  font-weight: bold;
}
.customHeader .topHeader .infoColumn .convLinks a:last-child {
  margin-right: 0;
}
.customHeader .topHeader .infoColumn .convLinks a:last-child:after {
  display: none;
}
.customHeader .topHeader .infoColumn .convLinks a:after {
  content: "";
  position: absolute;
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  width: 1px;
  height: 15px;
  background: #254f9c;
}
.customHeader .topHeader .infoColumn > .actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
}
@media only screen and (max-width: 991px) {
  .customHeader .topHeader .infoColumn > .actions {
    margin-top: 0;
  }
}
.customHeader .topHeader .infoColumn > .actions .block-search {
  margin-right: 35px;
  width: 100%;
  max-width: 560px;
}
@media only screen and (min-width: 767px) and (max-width: 991px) {
  .customHeader .topHeader .infoColumn > .actions .block-search {
    width: 250px;
  }
}
@media only screen and (max-width: 767px) {
  .customHeader .topHeader .infoColumn > .actions .block-search {
    width: auto;
    margin-right: 0;
    margin-top: 0;
  }
}
.customHeader .topHeader .infoColumn > .actions .block-search .search #search {
  left: -9999px;
  margin: 0;
}
@media only screen and (max-width: 767px) {
  .customHeader .topHeader .infoColumn > .actions .block-search .search {
    position: relative;
  }
  .customHeader .topHeader .infoColumn > .actions .block-search .search label.label {
    display: block;
    width: 35px;
    height: 35px;
    padding: 2px;
    border: 1px solid #254f9c;
    border-radius: 50%;
    position: relative;
    margin-right: 15px;
  }
}
@media only screen and (max-width: 767px) and (max-width: 450px) {
  .customHeader .topHeader .infoColumn > .actions .block-search .search label.label {
    margin-right: 10px;
  }
}
@media only screen and (max-width: 767px) {
  .customHeader .topHeader .infoColumn > .actions .block-search .search label.label:before {
    width: 23px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 !important;
    color: #254f9c;
  }
}
@media only screen and (max-width: 767px) {
  .customHeader .topHeader .infoColumn > .actions .block-search .search label.label.active ~ .control {
    border: 1px solid #ccc !important;
  }
}
@media only screen and (max-width: 767px) {
  .customHeader .topHeader .infoColumn > .actions .block-search .search .control {
    position: absolute !important;
    top: 37px !important;
    right: 0;
    width: 280px !important;
    left: auto !important;
  }
}
.customHeader .topHeader .infoColumn > .actions .block-search #search_mini_form {
  width: 100%;
}
.customHeader .topHeader .infoColumn > .actions .block-search #search_mini_form #searchautocomplete {
  width: 100% !important;
}
.customHeader .topHeader .infoColumn > .actions .block-search #search_mini_form #searchautocomplete .container-autocomplete .product-list li {
  padding-bottom: 0;
  margin-bottom: 5px;
}
.customHeader .topHeader .infoColumn > .actions .block-search #search {
  border: 0;
  background: #ddd;
  padding: 8px 12px !important;
  font-size: 16px;
  max-width: 100%;
  width: 100%;
  height: 37px;
}
.customHeader .topHeader .infoColumn > .actions .block-search #search::placeholder, .customHeader .topHeader .infoColumn > .actions .block-search #search::-webkit-input-placeholder, .customHeader .topHeader .infoColumn > .actions .block-search #search::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #7E7E7E !important;
  opacity: 1; /* Firefox */
  font-size: 16px;
}
.customHeader .topHeader .infoColumn > .actions .block-search button.search:before {
  color: #254f9c;
}
.customHeader .topHeader .infoColumn > .actions .storeWrap {
  display: flex;
}
.customHeader .topHeader .infoColumn > .actions .storeWrap a {
  display: inline-flex;
  align-items: center;
}
@media only screen and (max-width: 991px) {
  .customHeader .topHeader .infoColumn > .actions .storeWrap a {
    display: block;
  }
}
.customHeader .topHeader .infoColumn > .actions .storeWrap a span {
  text-transform: none;
  color: #254f9c;
  font-size: 18px;
}
@media only screen and (max-width: 991px) {
  .customHeader .topHeader .infoColumn > .actions .storeWrap a span {
    display: none;
  }
}
.customHeader .topHeader .infoColumn > .actions .storeWrap .storeWrapInner {
  display: block;
  width: 35px;
  height: 35px;
  padding: 2px;
  border: 1px solid #254f9c;
  border-radius: 50%;
  position: relative;
  margin-right: 10px;
}
@media only screen and (max-width: 450px) {
  .customHeader .topHeader .infoColumn > .actions .storeWrap .storeWrapInner {
    margin-right: 7px;
  }
}
.customHeader .topHeader .infoColumn > .actions .storeWrap .storeWrapInner img {
  width: 23px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media only screen and (max-width: 450px) {
  .customHeader .topHeader .infoColumn > .actions .minicart-wrapper {
    padding-left: 0;
  }
}
.customHeader .topHeader .infoColumn > .actions .minicart-wrapper a.showcart {
  display: block;
  width: 35px;
  height: 35px;
  padding: 2px;
  border: 1px solid #254f9c;
  border-radius: 50%;
  position: relative;
  margin-right: 84px;
  top: 0;
}
@media only screen and (max-width: 991px) {
  .customHeader .topHeader .infoColumn > .actions .minicart-wrapper a.showcart {
    margin-right: 48px;
  }
}
@media only screen and (max-width: 450px) {
  .customHeader .topHeader .infoColumn > .actions .minicart-wrapper a.showcart {
    margin-right: 39px;
  }
}
.customHeader .topHeader .infoColumn > .actions .minicart-wrapper a.showcart .text {
  width: auto;
  height: auto;
  position: relative;
  padding-left: 45px;
  line-height: 35px;
  color: #254f9c;
  font-size: 18px;
  text-transform: none;
}
@media only screen and (max-width: 991px) {
  .customHeader .topHeader .infoColumn > .actions .minicart-wrapper a.showcart .text {
    display: none;
  }
}
.customHeader .topHeader .infoColumn > .actions .minicart-wrapper a.showcart:before {
  content: "";
  background: url("../images/shopping-basket.svg");
  background-position: center center;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.customHeader .topHeader .infoColumn > .actions .minicart-wrapper a.showcart .counter.qty {
  position: absolute;
  left: 20px;
  top: -12px;
  border-radius: 50%;
  border-color: #254f9c;
  background: #254f9c;
}
@media only screen and (max-width: 767px) {
  .customHeader .topHeader .infoColumn > .actions .minicart-wrapper a.showcart .counter.qty {
    min-width: 20px;
    height: 20px;
    line-height: 18px;
    padding: 0;
  }
}
.customHeader .topHeader .infoColumn > .actions .minicart-wrapper a.showcart .counter.qty .counter-number {
  color: #fff;
}
.customHeader .topHeader .infoColumn > .actions .header.links {
  display: none;
}
.customHeader .topHeader .infoColumn > .actions .minicart-wrapper {
  padding-left: 0;
  margin-left: 0;
}
@media only screen and (max-width: 767px) {
  .customHeader .topHeader .infoColumn > .actions .minicart-wrapper .product .actions {
    float: right;
    margin: -24px 0 0;
  }
}
.customHeader .menuHeader {
  background: #254f9c;
  margin-left: -15px;
  margin-right: -15px;
  position: relative;
}
.customHeader .menuHeader .nav-sections {
  float: none;
}
.customHeader .menuHeader .nav-sections .navigation {
  background: transparent;
  max-width: none;
}
.customHeader .menuHeader .nav-sections .navigation .level0 > .level-top {
  color: #fff;
}
.customHeader .menuHeader .nav-sections .navigation .level0 > .level-top:after {
  content: "";
  position: absolute;
  right: -15px;
  top: 0;
  display: block;
  width: 1px;
  height: 100%;
  background: #fff;
}
.customHeader .menuHeader .nav-sections .navigation .level0 .level0.submenu {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  width: 100%;
  left: 0 !important;
  max-width: 1800px;
}
.customHeader .menuHeader .nav-sections .navigation .level0 .level0.submenu > li.submenu-child {
  display: flex;
  flex-wrap: wrap;
}
.customHeader .menuHeader .nav-sections .navigation .level0 .level0.submenu > li.submenu-child > ul {
  display: block !important;
  width: 33.333% !important;
}
.customHeader .menuHeader .nav-sections .navigation > ul {
  background: transparent;
  display: flex;
  flex-wrap: wrap;
}
.customHeader .menuHeader .nav-sections .navigation > ul > li.level0 {
  padding: 0 15px;
  margin: 0;
  transition: 0.3s ease-in-out;
}
.customHeader .menuHeader .nav-sections .navigation > ul > li.level0 a {
  transition: 0.3s ease-in-out;
}
.customHeader .menuHeader .nav-sections .navigation > ul > li.level0 .level-top {
  padding: 0;
}
.customHeader .menuHeader .nav-sections .navigation ul li.megamenu.level1:not(.parent):hover > a:hover {
  color: #000 !important;
}
.customHeader .headerServices {
  margin-left: -15px;
  margin-right: -15px;
}
.customHeader .headerServices .headerServicesInner {
  display: flex;
}
.customHeader .headerServices .headerServicesInner .serviceItem {
  border: 1px solid #ccc;
  flex: 1;
  text-align: center;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.customHeader .headerServices .headerServicesInner .serviceItem a {
  transition: 0.3s ease-in-out;
}
.customHeader .headerServices .headerServicesInner .serviceItem a:hover {
  color: #254f9c;
}
.customHeader .headerServices .headerServicesInner .serviceItem span {
  display: block;
  width: 100%;
}
.customHeader .headerServices .headerServicesInner .serviceItem:nth-child(2) {
  border-left: 0;
  border-right: 0;
}
.customHeader.sticky .stickyPlaceholder {
  height: 125px;
}
.customHeader.sticky .topHeader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background: #fff;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}
.customHeader.sticky .topHeader .topHeaderWrap {
  padding: 10px 15px;
  max-width: 1831px;
  margin: 0 auto;
}
.customHeader.sticky .topHeader .logoWrap {
  width: 200px;
}
.customHeader.sticky .topHeader .logoWrap .logo img {
  width: auto;
}
.customHeader.sticky .topHeader .logoWrap .nav-toggle {
  top: 20px !important;
  right: 15px;
}
@media only screen and (max-width: 767px) {
  .customHeader.sticky .topHeader .logoWrap .nav-toggle {
    top: 9px !important;
  }
}
.customHeader.sticky .infoColumn .convLinks {
  display: none;
}
.customHeader.sticky .infoColumn > .actions {
  margin-top: 0;
}

.category-section {
  display: flex;
  flex-wrap: wrap;
}
.category-section h3 {
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .category-section h3 {
    padding: 0 15px;
  }
}
.category-section .catItem {
  padding: 0 15px;
  width: 25%;
  margin-bottom: 15px;
}
@media only screen and (max-width: 991px) {
  .category-section .catItem {
    width: 33.333%;
  }
}
@media only screen and (max-width: 767px) {
  .category-section .catItem {
    width: 50%;
  }
}
@media only screen and (max-width: 500px) {
  .category-section .catItem {
    width: 100%;
  }
}
.category-section .catItem .category-product {
  position: relative;
}
.category-section .catItem .category-product .img-holder {
  background-size: cover;
  padding-bottom: 100%;
}
.category-section .catItem .category-product .catInfo {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  text-align: center;
  display: block;
}
.category-section .catItem .category-product .catInfo .buttonLook {
  color: #fff;
  background: #283583;
  padding: 8px 20px;
  display: inline-block;
  margin-bottom: 5px;
  font-size: 19px;
  font-weight: bold;
}
@media only screen and (max-width: 991px) {
  .category-section .catItem .category-product .catInfo .buttonLook {
    font-size: 17px;
  }
}
.category-section .catItem .category-product .catInfo h4 {
  color: #fff;
  font-weight: bold;
  font-size: 21px;
  text-transform: none;
  display: block;
  margin-bottom: 3px;
  margin-top: 0;
  line-height: 30px;
}
@media only screen and (max-width: 991px) {
  .category-section .catItem .category-product .catInfo h4 {
    font-size: 19px;
    line-height: 27px;
  }
}
.category-section .catItem .category-product .catInfo > div {
  align-self: center;
  width: 100%;
}

.buttonLook {
  display: inline-block;
  background: #254f9c;
  padding: 5px 10px;
}

.products.products-related .product-item .minimal-price .price-label, .products.products-crosssell .product-item .minimal-price .price-label, .products.products-upsell .product-item .minimal-price .price-label {
  display: block !important;
}
.products.products-related .product-item .product-item-name .product-item-link, .products.products-crosssell .product-item .product-item-name .product-item-link, .products.products-upsell .product-item .product-item-name .product-item-link {
  font-size: 16px !important;
  font-weight: bold;
}
.products.products-related .product-item .field.choice.related, .products.products-crosssell .product-item .field.choice.related, .products.products-upsell .product-item .field.choice.related {
  width: auto !important;
}
.products.products-related .product-item .field.choice.related label span, .products.products-crosssell .product-item .field.choice.related label span, .products.products-upsell .product-item .field.choice.related label span {
  font-size: 15px;
}
.products.products-related .product-item .product-item-actions, .products.products-crosssell .product-item .product-item-actions, .products.products-upsell .product-item .product-item-actions {
  margin-top: 0 !important;
}
.products.list .minimal-price .price-label {
  display: block !important;
}
.products.list .item {
  position: relative;
}
.products.list .item:after {
  content: "";
  display: block;
  border-bottom: 1px dotted #ccc;
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 100%;
}
.products.list .item .product-item-actions {
  text-align: center;
}
.products.list .item .product-item-actions .actions-secondary {
  display: none;
}
.products.list .item .weltpixel-quickview {
  display: none !important;
}
.products.list .item .stock.unavailable {
  font-size: 12px;
  margin-bottom: 5px;
  padding: 0;
}
.products.list .item a.action {
  background: #254f9c !important;
}
.products.list .item a.action:hover {
  background: #bb7e00 !important;
}

.page-products .grid.products-grid .product-items {
  display: flex;
  flex-wrap: wrap;
}
.page-products .grid.products-grid .product-items .product-item {
  margin: 0 10px 10px 10px !important;
  width: calc(20% - 20px) !important;
}
@media only screen and (max-width: 991px) {
  .page-products .grid.products-grid .product-items .product-item {
    width: calc(33.333% - 20px) !important;
  }
}
@media only screen and (max-width: 767px) {
  .page-products .grid.products-grid .product-items .product-item {
    width: calc(50% - 20px) !important;
  }
}
@media only screen and (max-width: 500px) {
  .page-products .grid.products-grid .product-items .product-item {
    width: calc(100% - 20px) !important;
  }
}
.page-products .grid.products-grid .product-items .product-item .product-item-info:not(:hover) .product-item-inner {
  width: 0;
  overflow: hidden;
}
@media only screen and (min-width: 767px) {
  .page-products .grid.products-grid .product-items .product-item .product-item-info:not(:hover) .tocart {
    border: 1px solid transparent;
    width: 0;
    background: none;
    transition: none;
  }
}
.page-products .grid.products-grid .product-items .product-item .minimal-price .price-label {
  display: block !important;
}
.page-products .grid.products-grid .product-items .product-item .actions-primary .tocart {
  font-size: 13px;
  text-transform: uppercase;
  border: 2px solid #254f9c;
  padding: 0;
  display: flex;
  align-items: center;
  margin: 0 auto;
}
.page-products .grid.products-grid .product-items .product-item .actions-primary .tocart:hover {
  background: #254f9c;
}
.page-products .grid.products-grid .product-items .product-item .actions-primary .tocart:hover > span {
  background: #bb7e00;
  color: #fff;
}
.page-products .grid.products-grid .product-items .product-item .actions-primary .tocart:before {
  content: "";
  background-image: url("../images/shopping-cart-solid.svg");
  padding: 14px 22px;
  background-size: 18px;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
}
.page-products .grid.products-grid .product-items .product-item .actions-primary .tocart > span {
  background: #fff;
  color: #bb7e00;
  padding: 7px 12px;
  display: inline-block;
  font-size: 15px;
  font-weight: bold;
}
.page-products .grid.products-grid .product-items .product-item .product-item-info .tocart {
  transition: none;
}
.page-products .grid.products-grid .product-items .product-item .product-item-inner {
  height: auto !important;
}
@media only screen and (max-width: 767px) {
  .page-products .grid.products-grid .product-items .product-item .product-item-inner {
    position: relative;
    width: auto;
    top: auto;
  }
}
.page-products .products-list .products .product .actions-primary .tocart {
  font-size: 13px;
  text-transform: uppercase;
  border: 2px solid #254f9c;
  padding: 0;
  display: flex;
  align-items: center;
}
.page-products .products-list .products .product .actions-primary .tocart:hover {
  background: #254f9c;
}
.page-products .products-list .products .product .actions-primary .tocart:hover > span {
  background: #bb7e00;
  color: #fff;
}
.page-products .products-list .products .product .actions-primary .tocart:before {
  content: "";
  background-image: url("../images/shopping-cart-solid.svg");
  padding: 14px 22px;
  background-size: 18px;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
}
.page-products .products-list .products .product .actions-primary .tocart > span {
  background: #fff;
  color: #bb7e00;
  padding: 7px 12px;
  display: inline-block;
  font-size: 15px;
  font-weight: bold;
}

.block-products-list h2 {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 30px;
  font-weight: bold;
}
@media only screen and (max-width: 767px) {
  .block-products-list h2 {
    font-size: 25px;
  }
}

.widget-product-carousel.product-items {
  margin-bottom: 50px;
}
.widget-product-carousel.product-items .product-item-inner .product-item-actions .actions-primary {
  display: block;
  margin: 0 auto;
}
.widget-product-carousel.product-items .product-item-inner .product-item-actions .actions-secondary {
  display: none;
}

.widget-product-grid.product-items .product-item-details .product-item-link {
  font-size: 20px;
}

.products-grid .product-item .product-item-name a {
  font-size: 20px !important;
}
.products-grid .product-item .packaging-details {
  margin-bottom: 20px;
  text-align: center;
}
.products-grid .product-item .packaging-details .packaging {
  font-size: 15px;
}
.products-grid .product-item .packaging-details .individual_price {
  display: none;
}
.products-grid .product-item .price-box {
  margin: 10px 0 8px;
}

.header .quickcart-wrapper .block-quickcart {
  box-shadow: -4px 0px 20px -2px rgba(0, 0, 0, 0.75);
}
.header .quickcart-wrapper .block-quickcart .block-content-quickcart .quickcart-top {
  background: #254f9c;
}

.checkout-cart-index .columns {
  padding: 0 15px;
}
.checkout-cart-index .cart-empty a {
  color: #254f9c;
  text-decoration: underline;
}

.owl-carousel-products-upsell .owl-thumbs {
  display: none;
}

@media only screen and (min-width: 767px) {
  .page-main {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media only screen and (min-width: 767px) {
  .cms-index-index .page-main {
    padding-left: 0;
    padding-right: 0;
  }
}

.toolbar.toolbar-products {
  padding: 10px 0;
}
.toolbar.toolbar-products .toolbar-sorter.sorter #sorter {
  margin-right: 0;
}

#layer-product-list .toolbar .pages {
  display: block !important;
}
#layer-product-list .search .toolbar .pages {
  display: block !important;
}

.ias-load-prev, .ias-no-more {
  display: none !important;
}

#layer-product-list > .toolbar:first-child .pages {
  display: none !important;
}
#layer-product-list .search > .toolbar:first-child .pages {
  display: none !important;
}

.theme-pearl.store-view-default.catalog-product-view .page.messages {
  max-width: 1801px !important;
}

#wpn-pearl-recaptcha-f77ddbc107973f97c243776865706675ebc87a42-container,
#recaptcha-caa20ac351216e6f76edced1efe7c61fff5f4796-container {
  visibility: hidden;
  opacity: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

@media only screen and (max-width: 767px) {
  .page-wrapper .btt-button {
    bottom: 15px !important;
  }
}

.copyright {
  background: #303030;
}

#layered-horizontal-container {
  border-bottom: 0 !important;
}
@media only screen and (min-width: 768px) {
  #layered-horizontal-container #layered-filter-block {
    border-bottom: 1px solid #ccc;
  }
}

.slick-prev:before, .slick-next:before, .slick-prev:after, .slick-next:after,
.slick-prev:hover:before, .slick-next:hover:before {
  color: #fff;
}

#layered-filter-block-container .filter-content .filter-options .layer-input-filter {
  display: none;
}
#layered-filter-block-container .filter-content .filter-options .layer-input-filter:checked ~ a:before {
  background: #000;
}
#layered-filter-block-container .filter-content .filter-options .filter-options-item .filter-options-content .items .item {
  margin: 10px;
}
#layered-filter-block-container .filter-content .filter-options .filter-options-item .filter-options-content .items .item a {
  width: auto;
  padding-left: 0;
  margin: 0;
}
#layered-filter-block-container .filter-content .filter-options .filter-options-item .filter-options-content .items .item a:before {
  margin: 4px 5px 0 0;
}

.gridBanner .gridBannerInner {
  margin-left: -25px;
  margin-right: -25px;
}
.gridBanner .gridBannerInner ul {
  padding-left: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
}
.gridBanner .gridBannerInner ul li {
  width: calc(25% - 50px);
  margin: 25px;
  position: relative;
}
@media only screen and (max-width: 1366px) {
  .gridBanner .gridBannerInner ul li {
    width: calc(33.3333% - 50px);
  }
}
@media only screen and (max-width: 991px) {
  .gridBanner .gridBannerInner ul li {
    width: calc(50% - 50px);
  }
  .gridBanner .gridBannerInner ul li .info .gridtitle {
    font-size: 19px !important;
  }
  .gridBanner .gridBannerInner ul li .info .btnLookBlue {
    font-size: 17px !important;
  }
}
@media only screen and (max-width: 500px) {
  .gridBanner .gridBannerInner ul li {
    width: calc(100% - 50px);
  }
}
.gridBanner .gridBannerInner ul li .img {
  display: block;
  width: 100%;
  padding-bottom: 100%;
  background-size: 100%;
  background-position: center center;
  transition: 0.3s ease-in-out;
}
.gridBanner .gridBannerInner ul li a:hover .img {
  background-size: 120%;
}
.gridBanner .gridBannerInner ul li .info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  text-align: center;
  display: block;
}
.gridBanner .gridBannerInner ul li .info .gridtitle {
  color: #fff;
  font-weight: bold;
  font-size: 21px;
  text-transform: none;
  display: block;
  margin-bottom: 3px;
}
.gridBanner .gridBannerInner ul li .info .btnLookBlue {
  color: #fff;
  background: #283583;
  padding: 8px 20px;
  display: inline-block;
  margin-bottom: 5px;
  font-size: 19px;
  font-weight: bold;
}

.page-footer {
  background-color: #254f9c !important;
}

.footer .subBlock {
  background: #fff;
  padding-bottom: 70px;
  padding-top: 70px;
  position: relative;
}
.footer .subBlock:after {
  content: "";
  display: block;
  width: calc(100% - 30px);
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  border-top: 1px solid #ccc;
  max-width: 1801px;
}
@media only screen and (max-width: 766px) {
  .footer .subBlock:after {
    width: 100%;
  }
}
.footer .subBlock .row {
  display: flex;
  flex-wrap: wrap;
  color: #303030 !important;
}
@media only screen and (max-width: 767px) {
  .footer .subBlock .row {
    margin: 0 !important;
  }
}
.footer .subBlock .row .c-column {
  width: 50%;
  padding: 0 15px;
}
@media only screen and (max-width: 500px) {
  .footer .subBlock .row .c-column {
    width: 100%;
    margin-bottom: 10px;
  }
}
.footer .subBlock .row .c-column > h4 {
  text-align: center;
  margin: 10px 0 20px 0;
  font-size: 20px;
  font-weight: 600;
  color: #303030 !important;
}
.footer .subBlock .row .c-column p {
  color: #303030 !important;
}
.footer .subBlock .row .c-column .c-inner {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}
.footer .subBlock .row .c-column .c-inner img {
  height: 50px;
}
.footer .subBlock .row .c-column .c-inner.social a {
  margin: 0 15px;
}
.footer .subBlock .row .c-column .c-inner.social a:hover img {
  filter: invert(28%) sepia(30%) saturate(2613%) hue-rotate(195deg) brightness(89%) contrast(94%);
}
.footer .subBlock .row .c-column .c-inner.social a img {
  transition: 0.3s ease-in-out;
  margin: 0;
}
.footer .columnContainer {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px !important;
  margin-top: 30px !important;
  max-width: 835px !important;
}
@media only screen and (max-width: 767px) {
  .footer .columnContainer {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
.footer .columnContainer .c-column {
  width: 50%;
  padding: 0 15px;
}
@media only screen and (max-width: 500px) {
  .footer .columnContainer .c-column {
    width: 100%;
    margin-bottom: 10px;
  }
}
.footer .columnContainer .c-column > h4 {
  color: #fff !important;
  text-align: center;
  margin: 10px 0 0 0;
}
.footer .columnContainer .c-column .c-inner {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}
.footer .columnContainer .c-column .c-inner img {
  height: 40px;
  margin: 0 10px;
}
.footer .brandTitle {
  background: #fff;
  margin: 0;
  padding: 0 5px 20px 5px;
  text-align: center;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 30px;
  font-weight: bold;
}
.footer .pre-footer .pre-footer-content {
  margin-left: -10px;
  margin-right: -10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.footer .pre-footer .pre-footer-content .brand-item {
  margin: 10px auto;
  padding: 0 10px;
}
.footer .pre-footer .pre-footer-content .brand-item img {
  max-width: 100%;
}
.footer .footer-v3 {
  background-color: #254f9c !important;
}
.footer .footer-v3 .col-md-12 {
  float: none !important;
}
.footer .footer-v3 ul {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 0 !important;
}
@media only screen and (max-width: 991px) {
  .footer .footer-v3 ul {
    flex-wrap: wrap;
  }
}
.footer .footer-v3 ul li {
  padding: 0 30px;
  position: relative;
  text-align: center;
}
@media only screen and (max-width: 991px) {
  .footer .footer-v3 ul li {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 500px) {
  .footer .footer-v3 ul li {
    width: 100%;
  }
}
.footer .footer-v3 ul li a {
  color: #fff !important;
  font-size: 24px;
}
@media only screen and (max-width: 1800px) {
  .footer .footer-v3 ul li a {
    font-size: 19px;
  }
}
@media only screen and (max-width: 1366px) {
  .footer .footer-v3 ul li a {
    font-size: 16px;
  }
}
.footer .footer-v3 ul li:last-child:after {
  display: none;
}
.footer .footer-v3 ul li:after {
  content: "";
  display: block;
  height: 60%;
  width: 2px;
  background: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
@media only screen and (max-width: 500px) {
  .footer .footer-v3 ul li:after {
    display: none;
  }
}
.footer .footer-v3 .cards {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.footer .footer-v3 .cards img {
  height: 40px;
  margin: 0 10px;
}
.footer .newsletter-subscribe .form-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
}
@media only screen and (max-width: 500px) {
  .footer .newsletter-subscribe .form-group {
    flex-wrap: wrap;
  }
  .footer .newsletter-subscribe .form-group input {
    width: 100%;
  }
}
.footer .newsletter-subscribe .form-group input {
  width: auto;
  max-width: none;
  padding: 10px;
  height: 45px;
  text-align: center;
  font-size: 20px;
}
@media only screen and (max-width: 767px) {
  .footer .newsletter-subscribe .form-group input {
    width: 100%;
    font-size: 16px;
    flex: 1;
    text-align: left;
  }
}
.footer .newsletter-subscribe .form-group input::placeholder {
  color: #ababab;
  opacity: 1;
}
.footer .newsletter-subscribe .form-group input:-ms-input-placeholder {
  color: #ababab;
}
.footer .newsletter-subscribe .form-group input::-ms-input-placeholder {
  color: #ababab;
}
.footer .newsletter-subscribe .form-group button {
  margin-left: 0;
  margin: 0;
  font-size: 20px;
}
.footer .newsletter-subscribe .form-group button:hover {
  background-color: #bb7e00;
  border: #bb7e00 1px solid;
}
@media only screen and (max-width: 767px) {
  .footer .newsletter-subscribe .form-group button {
    font-size: 16px;
    width: 100%;
  }
}
.footer .newsletter-subscribe .form-group h3 {
  width: 100%;
  text-align: center;
  font-size: 17px;
}
@media only screen and (min-width: 768px) {
  .footer .newsletter-subscribe .form-group h3 {
    display: none;
  }
}
.footer .newsletter-subscribe .form-group .newsletterInfo {
  width: 100%;
  text-align: center;
  margin-top: 10px;
}
@media only screen and (max-width: 767px) {
  .footer .newsletter-subscribe .form-group .newsletterInfo {
    text-align: left;
  }
}
.footer .newsletter-subscribe .form-group .newsletterInfo p.small {
  font-size: 11px;
}
.footer .newsletter-subscribe .form-group .newsletterInfo a {
  color: #303030 !important;
  text-decoration: underline;
}

.header .quickcart-wrapper .product-image-container {
  width: auto !important;
}
.header .quickcart-wrapper .notice {
  text-transform: none;
  color: red;
  float: left;
}

.cart-container .cart.item .notice {
  color: red;
  font-size: 12px;
}
.cart-container .cart.item .notice td {
  padding: 0 0 10px 0 !important;
}

.store-banner {
  position: relative;
  overflow: hidden;
}
.store-banner .owl-nav {
  display: none;
}
.store-banner .owl-dots {
  text-align: center;
}
.store-banner .owl-dots button {
  background: transparent !important;
  padding: 0 !important;
  border: 0 !important;
}
@media only screen and (max-width: 767px) {
  .store-banner .owl-item {
    height: 350px;
    position: relative;
    overflow: hidden;
  }
}
.store-banner .mobileImage {
  display: none !important;
}
@media only screen and (max-width: 767px) {
  .store-banner .mobileImage {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    max-height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: auto;
    max-width: none;
    display: block !important;
  }
}
.store-banner .store-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.store-banner .store-container.overlay {
  background: rgba(0, 0, 0, 0.4);
}
.store-banner .store-container .store-box {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  left: 0;
  padding: 0 10px;
  max-width: 475px;
  margin-left: 140px;
}
@media only screen and (max-width: 991px) {
  .store-banner .store-container .store-box {
    margin-left: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.store-banner img {
  width: 100%;
}
@media only screen and (max-width: 991px) {
  .store-banner {
    text-align: center;
  }
}
.store-banner:after {
  content: "";
  display: block;
  clear: both;
}
.store-banner .store-container {
  float: left;
}
@media only screen and (max-width: 991px) {
  .store-banner .store-container {
    float: none;
    display: inline-block;
    text-align: center;
  }
}
.store-banner .store-container .store-box .store-title {
  color: #fff;
  display: block;
  margin-bottom: 18px;
  font-weight: bold;
  font-size: 50px;
  line-height: 53px;
  text-shadow: 1px 0px 3px rgba(0, 0, 0, 0.2);
}
@media only screen and (max-width: 576px) {
  .store-banner .store-container .store-box .store-title {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 4px;
  }
}
.store-banner .store-container .store-box .store-text {
  font-size: 24px;
  color: #fff;
  display: block;
  line-height: 26px;
  font-weight: bold;
  text-shadow: 1px 0px 3px rgba(0, 0, 0, 0.2);
}
@media only screen and (max-width: 576px) {
  .store-banner .store-container .store-box .store-text {
    font-size: 19px;
    line-height: 23px;
  }
}
.store-banner > img {
  position: absolute;
  top: -9999px;
  right: -9999px;
  bottom: -9999px;
  left: -9999px;
  margin: auto;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
}

.catalog-category-view.page-layout-weltlayout #layer-product-list {
  padding: 0;
}
.catalog-category-view.page-layout-weltlayout .category-image {
  display: none;
}
.catalog-category-view .category-view {
  max-width: 1800px;
  margin: 0 auto;
}
@media only screen and (max-width: 767px) {
  .catalog-category-view .category-view {
    padding: 0;
  }
}
.catalog-category-view .category-view .category-image img {
  width: 100%;
}
.catalog-category-view .category-view .breadcrumbs {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.catalog-category-view .category-view .breadcrumbs .items:after {
  content: "";
  display: block;
  clear: both;
}
@media only screen and (min-width: 768px) {
  .catalog-category-view #layered-horizontal-container, .catalog-category-view #layer-product-list {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.catalog-category-view #layered-horizontal-container {
  margin-bottom: 0;
  padding: 0;
}
.catalog-category-view #layered-horizontal-container #layered-filter-block {
  margin-bottom: 0;
}
.catalog-category-view #layered-horizontal-container #layered-filter-block .filter-options-title {
  border: 0;
}
.catalog-category-view .sidebar {
  display: none;
}

.catalog-product-view.theme-pearl.product-page-v4 .info.detailed > .items > .item.title > a {
  font-size: 1.8em;
}
.catalog-product-view.theme-pearl.product-page-v4 .info.detailed > .items > .item.title > a.switch {
  background-color: #ddd !important;
}
.catalog-product-view.theme-pearl.product-page-v4 .info.detailed > .items > .item.title > a.switch:hover {
  background-color: #ddd !important;
}
@media only screen and (max-width: 767px) {
  .catalog-product-view.theme-pearl.product-page-v4 .info.detailed > .items > .item.title > a {
    font-size: 1.4rem;
  }
}
.catalog-product-view.theme-pearl.product-page-v4 .info.detailed .item.content {
  background-color: #ddd !important;
  font-size: 1.6em;
}
@media only screen and (max-width: 767px) {
  .catalog-product-view.theme-pearl.product-page-v4 .info.detailed .item.content {
    font-size: 1.4rem;
  }
}
.catalog-product-view.theme-pearl.product-page-v4 .owl-nav.disabled, .catalog-product-view.theme-pearl.product-page-v4 .owl-dots.disabled {
  display: none;
}
.catalog-product-view.theme-pearl.product-page-v4 .lb-outerContainer .lb-nav {
  top: 37px;
}
.catalog-product-view.theme-pearl.product-page-v4 .lb-outerContainer .lb-data .lb-number {
  padding: 0;
  margin: 5px 0;
}
.catalog-product-view.theme-pearl.product-page-v4 #lightbox .lb-container {
  background: #fff;
}
.catalog-product-view.theme-pearl.product-page-v4 #lightbox .lb-dataContainer {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
}
.catalog-product-view.theme-pearl.product-page-v4 #lightbox .lb-dataContainer .lb-data {
  width: 100%;
}
.catalog-product-view.theme-pearl.product-page-v4 #lightbox .lb-dataContainer .lb-data .lb-details {
  width: initial;
  float: initial;
  text-align: center;
  line-height: 1.1em;
  color: black;
  display: inline-block;
  vertical-align: top;
}
.catalog-product-view.theme-pearl.product-page-v4 #lightbox .lb-dataContainer .lb-data .lb-closeContainer {
  display: inline-block;
  float: right;
}
@media only screen and (max-width: 1800px) {
  .catalog-product-view.theme-pearl.product-page-v4 .columns {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) {
  .catalog-product-view.theme-pearl.product-page-v4 .columns {
    max-width: 1800px !important;
  }
}
.catalog-product-view.theme-pearl.product-page-v4 .product-top-main {
  display: flex;
  flex-wrap: wrap;
  padding-top: 50px;
}
.catalog-product-view.theme-pearl.product-page-v4 .product-top-main .product.media {
  flex: 1;
  max-width: 1100px;
  min-width: 0;
}
@media only screen and (max-width: 767px) {
  .catalog-product-view.theme-pearl.product-page-v4 .product-top-main .product.media {
    max-width: none;
    width: 100%;
    flex: none;
  }
}
.catalog-product-view.theme-pearl.product-page-v4 .product-top-main .product.media:before {
  display: none;
}
.catalog-product-view.theme-pearl.product-page-v4 .product-top-main .product-info-main {
  margin-left: 50px;
  flex: 1;
  min-width: 400px;
  position: relative;
  left: auto;
  right: auto !important;
  top: auto;
  padding-top: 0;
}
@media only screen and (max-width: 767px) {
  .catalog-product-view.theme-pearl.product-page-v4 .product-top-main .product-info-main {
    min-width: 0;
    width: 100%;
    flex: none;
    margin-left: 0;
  }
}
.catalog-product-view.theme-pearl.product-page-v4 .product-top-main .product-info-main .product-add-form {
  padding-top: 0 !important;
}
.catalog-product-view.theme-pearl.product-page-v4 .product-top-main .product-info-main .product-add-form .box-tocart .fieldset .field {
  margin: 0;
}
.catalog-product-view.theme-pearl.product-page-v4 .product-top-main .product-info-main .product-add-form .box-tocart .control {
  position: relative;
  margin-right: 10px;
  width: 112px;
  text-align: center;
}
.catalog-product-view.theme-pearl.product-page-v4 .product-top-main .product-info-main .product-add-form .box-tocart .control input#qty {
  margin-right: 0;
}
.catalog-product-view.theme-pearl.product-page-v4 .product-top-main .product-info-main .product-add-form .box-tocart .control .qty-changer .qty_inc {
  height: 50px;
  border: 1px solid #c2c2c2;
  line-height: 48px;
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  font-size: 22px;
}
.catalog-product-view.theme-pearl.product-page-v4 .product-top-main .product-info-main .product-add-form .box-tocart .control .qty-changer .qty_dec {
  height: 50px;
  line-height: 48px;
  border: 1px solid #c2c2c2;
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  font-size: 22px;
}
.catalog-product-view.theme-pearl.product-page-v4 .product-top-main .product-info-main .product-add-form .box-tocart .control .mage-error:not(.qty) {
  position: absolute;
  bottom: -20px;
  width: 400px;
  left: 0;
}
.catalog-product-view.theme-pearl.product-page-v4 .product-top-main .product-info-main .product-add-form .box-tocart .actions button {
  margin-bottom: 0;
}
@media only screen and (max-width: 767px) {
  .catalog-product-view.theme-pearl.product-page-v4 .product-top-main .product-info-main .product-add-form .box-tocart .actions {
    width: calc(100% - 122px);
  }
}
.catalog-product-view.theme-pearl.product-page-v4 .product-top-main .product-info-main .product-add-form .box-tocart .extrainfo {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: red;
}
.catalog-product-view.theme-pearl.product-page-v4 .product-top-main .product-info-main .product-add-form .box-tocart .extrainfo em:after {
  content: " ";
}
.catalog-product-view.theme-pearl.product-page-v4 .product-top-main .product-info-main .product-add-form .box-tocart .extrainfo span {
  margin-top: 5px;
}
.catalog-product-view.theme-pearl.product-page-v4 .product-top-main .product-info-main .product-info-price {
  display: flex;
  align-items: center;
  position: relative;
}
.catalog-product-view.theme-pearl.product-page-v4 .product-top-main .product-info-main .product.attribute.overview .value {
  font-size: 1.6em;
}
.catalog-product-view.theme-pearl.product-page-v4 .product-top-main .product-info-main .product-info-stock-sku {
  order: 2;
  margin-left: auto;
}
.catalog-product-view.theme-pearl.product-page-v4 .product-top-main .product-info-main .product-info-stock-sku .product.attribute.sku .type:after {
  content: ":";
}
@media only screen and (max-width: 767px) {
  .catalog-product-view.theme-pearl.product-page-v4 .product-top-main .product-info-main .price-box {
    padding-bottom: 45px;
  }
}
.catalog-product-view.theme-pearl.product-page-v4 .product-top-main .product-info-main .packaging,
.catalog-product-view.theme-pearl.product-page-v4 .product-top-main .product-info-main .individual_price {
  display: block;
}
@media only screen and (max-width: 767px) {
  .catalog-product-view.theme-pearl.product-page-v4 .product-top-main .product-info-main .packaging,
.catalog-product-view.theme-pearl.product-page-v4 .product-top-main .product-info-main .individual_price {
    position: absolute;
    bottom: 20px;
    left: 0;
  }
}
@media only screen and (max-width: 639px) {
  .catalog-product-view.theme-pearl.product-page-v4 .product-top-main .product-info-main .packaging,
.catalog-product-view.theme-pearl.product-page-v4 .product-top-main .product-info-main .individual_price {
    padding-left: 10px;
  }
}
.catalog-product-view.theme-pearl.product-page-v4 .product-top-main .product-info-main .individual_price {
  padding-bottom: 10px;
}
@media only screen and (max-width: 767px) {
  .catalog-product-view.theme-pearl.product-page-v4 .product-top-main .product-info-main .individual_price {
    bottom: 10px;
    height: 15px;
  }
}
.catalog-product-view.theme-pearl.product-page-v4 .product-top-main .product-info-main .product-social-links .tocompare:before {
  font-size: 21px !important;
  width: 22px !important;
  height: 24px;
  line-height: 19px !important;
}
.catalog-product-view.theme-pearl.product-page-v4 .product-top-main .product-info-main .product-social-links .tocompare span {
  font-size: 15px;
}
.catalog-product-view.theme-pearl.product-page-v4 .table-wrapper.grouped tbody td.item .price {
  font-size: 20px;
}
.catalog-product-view.theme-pearl .product-image .owl-dots {
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .catalog-product-view.theme-pearl .product-image .owl-dots {
    display: none;
  }
}
.catalog-product-view.theme-pearl .product-image .owl-dots button {
  background: none;
  border: 0;
  padding: 0;
}
.catalog-product-view.theme-pearl .product-image .owl-dots button.active {
  background: none;
  border: 0;
}
.catalog-product-view.theme-pearl .product-image .owl-nav > button {
  margin: 0;
  left: 0;
  top: 0;
  height: 100%;
  width: 60px;
  background: rgba(255, 255, 255, 0.8) !important;
  font-size: 0;
  text-indent: 0;
  transition: all 0.3s ease-in-out;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  border: none;
}
.catalog-product-view.theme-pearl .product-image .owl-nav > button > span {
  display: none;
}
.catalog-product-view.theme-pearl .product-image .owl-nav > button:after {
  content: "";
  display: block;
  width: 40px;
  height: 40px;
  background-position: -72px -71px;
  background-size: 200px;
  background-image: url(../images/gallery.png) !important;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.catalog-product-view.theme-pearl .product-image .owl-nav > button.owl-next {
  left: auto;
  right: 0;
}
.catalog-product-view.theme-pearl .product-image .owl-nav > button.owl-next:after {
  background-position: -130px -71px;
}
.catalog-product-view.theme-pearl .product-image:hover .owl-next, .catalog-product-view.theme-pearl .product-image:hover .owl-prev {
  visibility: visible;
  opacity: 1;
}
.catalog-product-view.theme-pearl .product-image .owl-item {
  position: relative;
}
.catalog-product-view.theme-pearl .product-image .owl-item a {
  display: block;
  padding-bottom: 100%;
}
.catalog-product-view.theme-pearl .product-image .owl-item img {
  height: auto;
  left: 50%;
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  transition-duration: 0.3s;
  transition-property: width, height, top, left;
  vertical-align: middle;
  width: auto;
}
@media only screen and (max-width: 767px) {
  .catalog-product-view.theme-pearl .thumbnails {
    display: none;
  }
}
.catalog-product-view.theme-pearl .thumbnails .owl-nav > button {
  margin: 0;
  left: 0;
  top: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.8) !important;
  font-size: 0;
  text-indent: 0;
  transition: all 0.3s ease-in-out;
  visibility: hidden;
  opacity: 0;
  width: 52px;
  position: absolute;
  border: none;
}
.catalog-product-view.theme-pearl .thumbnails .owl-nav > button > span {
  display: none;
}
.catalog-product-view.theme-pearl .thumbnails .owl-nav > button:after {
  content: "";
  display: block;
  width: 40px;
  height: 40px;
  background-position: -72px -71px;
  background-size: 200px;
  background-image: url(../images/gallery.png) !important;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.catalog-product-view.theme-pearl .thumbnails .owl-nav > button.owl-next {
  left: auto;
  right: 0;
}
.catalog-product-view.theme-pearl .thumbnails .owl-nav > button.owl-next:after {
  background-position: -130px -71px;
}
.catalog-product-view.theme-pearl .thumbnails .owl-next, .catalog-product-view.theme-pearl .thumbnails .owl-prev {
  width: 52px;
}
.catalog-product-view.theme-pearl .thumbnails:hover .owl-next, .catalog-product-view.theme-pearl .thumbnails:hover .owl-prev {
  visibility: visible;
  opacity: 1;
}
.catalog-product-view.theme-pearl .thumbnails .owl-item {
  height: 90px;
  position: relative;
  border: 1px solid transparent;
}
.catalog-product-view.theme-pearl .thumbnails .owl-item img {
  height: auto;
  left: 50%;
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  transition-duration: 0.3s;
  transition-property: width, height, top, left;
  vertical-align: middle;
  width: auto;
}
.catalog-product-view.theme-pearl .thumbnails .owl-item.activeDisplay {
  border: 1px solid #ff5501;
}
.catalog-product-view.theme-pearl table.grouped .item .product-item-name {
  width: 100%;
}
.catalog-product-view.theme-pearl table.grouped .item .price-box,
.catalog-product-view.theme-pearl table.grouped .item .packaging-details {
  display: inline-block;
  vertical-align: middle;
}
.catalog-product-view.theme-pearl table.grouped .item .packaging-details {
  margin-left: 10px;
}
.catalog-product-view.theme-pearl table.grouped .item .packaging-details .individual_price {
  padding-bottom: 0;
}
@media only screen and (max-width: 767px) {
  .catalog-product-view.theme-pearl table.grouped .item .packaging-details .packaging,
.catalog-product-view.theme-pearl table.grouped .item .packaging-details .individual_price {
    position: relative;
    bottom: auto;
    left: auto;
    height: auto;
  }
}
.catalog-product-view #product_stock_alert_container .stockalert .title {
  display: flex;
  width: 100%;
  margin-bottom: 5px;
}
.catalog-product-view #product_stock_alert_container .bss_productstockaler {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.catalog-product-view #product_stock_alert_container .bss_productstockaler .field {
  flex: 1;
}
.catalog-product-view #product_stock_alert_container .bss_productstockaler .field input {
  height: 34px;
}

.checkout-index-index ul#ui-id-1 {
  width: inherit;
}
.checkout-index-index .header .actions .customerLink {
  margin-right: 0;
}
@media only screen and (min-width: 992px) {
  .checkout-index-index .header .actions .block-search {
    margin-right: 0 !important;
  }
}
@media only screen and (max-width: 767px) {
  .checkout-index-index .header .actions .block-search {
    margin-right: 30px !important;
  }
}
.checkout-index-index .nav-sections {
  display: block;
}
.checkout-index-index .columns {
  padding-top: 40px !important;
}
@media only screen and (min-width: 768px) {
  .checkout-index-index .columns {
    padding: 0 15px;
  }
}
.checkout-index-index .columns .iosc-place-order-container .iosc-place-order-button.checkout {
  background: none;
  background-color: #254f9c;
  transition: all 0.3s ease-in-out;
  border: none;
}
.checkout-index-index .columns .iosc-place-order-container .iosc-place-order-button.checkout:hover {
  background-color: #bb7e00;
  color: #fff !important;
  border: none;
}
.checkout-index-index .columns .newaddress-save-button {
  background-color: #254f9c;
  border: none;
}
.checkout-index-index .columns .newaddress-save-button:hover {
  background-color: #bb7e00;
  color: #fff !important;
  border: none;
}
.checkout-index-index .columns .title-number {
  color: #254f9c;
  border-color: #254f9c;
  background-image: none;
  box-shadow: none;
}
.checkout-index-index .columns #opc-shipping_method .table-checkout-shipping-method thead th {
  vertical-align: top;
  text-align: center;
}
.checkout-index-index .columns #opc-shipping_method .table-checkout-shipping-method thead th.col-method:first-child {
  text-indent: -9999px;
}
.checkout-index-index .columns #opc-shipping_method .table-checkout-shipping-method tbody td {
  text-align: center;
  vertical-align: top;
  padding-bottom: 15px;
  padding-top: 15px;
}
.checkout-index-index .columns #checkout-payment-method-load .payment-method input.radio {
  vertical-align: middle;
  margin-top: 0;
}
.checkout-index-index .opc-wrapper .shipping-address-item.selected-item {
  border-color: #254f9c;
}
.checkout-index-index .opc-wrapper .shipping-address-item.selected-item:after {
  background: #254f9c;
}
.checkout-index-index .opc-wrapper .shipping-address-items {
  display: flex;
  flex-wrap: wrap;
}
.checkout-index-index .opc-wrapper .shipping-address-items .shipping-address-item {
  width: 50% !important;
}
.checkout-index-index .qtybuttons a.add:before, .checkout-index-index .qtybuttons a.remove:before {
  color: #254f9c;
}
.checkout-index-index .qtybuttons a.add:hover:before, .checkout-index-index .qtybuttons a.remove:hover:before {
  color: #bb7e00;
}
.checkout-index-index .notice {
  color: red;
  font-size: 12px;
  float: left;
  width: 100%;
}

.account .columns {
  padding-top: 40px !important;
}
@media only screen and (min-width: 768px) {
  .account .columns {
    padding: 0 15px;
  }
}
@media only screen and (min-width: 768px) {
  .account .columns .block-collapsible-nav {
    margin-bottom: 50px !important;
  }
}
.account .sidebar-additional .block-wishlist {
  display: none;
}

.catalogsearch-result-index .sidebar {
  display: none;
}
.catalogsearch-result-index #layered-horizontal-container {
  margin-bottom: 0;
}
.catalogsearch-result-index #layered-filter-block .block-content.filter-content .filter-options .filter-options-item .filter-options-title {
  border: 0;
}

@media only screen and (min-width: 768px) {
  .theme-pearl.store-view-default.cms-page-view .columns {
    max-width: 1800px !important;
  }
}
.theme-pearl.store-view-default.cms-page-view .page-title-wrapper {
  max-width: 1800px !important;
}
.theme-pearl.store-view-default.cms-page-view .page-wrapper .breadcrumbs {
  max-width: 1820px !important;
  margin: 0 auto;
}

.contact-index-index .page-main {
  background: white;
}

.checkout-onepage-success h1, .checkout-onepage-success h2, .checkout-onepage-success h3, .checkout-onepage-success h4, .checkout-onepage-success h5 {
  text-transform: none;
}
.checkout-onepage-success .thankyou-wrapper {
  max-width: 900px;
  margin: 0 auto;
}
.checkout-onepage-success .thankyou-wrapper .block.newsletter {
  margin: 10px 0;
}
.checkout-onepage-success .thankyou-wrapper .newsletter-checkout-wrapper {
  display: none;
}
.checkout-onepage-success .thankyou-wrapper .order-info {
  display: flex;
  flex-wrap: wrap;
}
.checkout-onepage-success .thankyou-wrapper .order-info > div {
  width: 50%;
  padding: 0 20px;
}
@media only screen and (max-width: 767px) {
  .checkout-onepage-success .thankyou-wrapper .order-info > div {
    width: 100%;
  }
}
.checkout-onepage-success .thankyou-wrapper .product-grid h3 {
  text-align: left;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 30px;
}
.checkout-onepage-success .thankyou-wrapper .customer-info h3 {
  text-align: left;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 30px;
}
.checkout-onepage-success .thankyou-wrapper .customer-info .actions-toolbar {
  margin-top: 30px;
}
@media only screen and (max-width: 767px) {
  .checkout-onepage-success .thankyou-wrapper .customer-info .actions-toolbar {
    display: none;
  }
}
.checkout-onepage-success .thankyou-wrapper .customer-info .actions-toolbar .primary {
  width: 100%;
}
.checkout-onepage-success .thankyou-wrapper .customer-info .actions-toolbar .primary .action.primary {
  padding: 15px;
}
.checkout-onepage-success .thankyou-wrapper .address-wrapper .shipping-address {
  text-align: left !important;
}
.checkout-onepage-success .thankyou-wrapper .shipping-method {
  text-align: left !important;
}
.checkout-onepage-success .thankyou-wrapper .order-wrapper,
.checkout-onepage-success .thankyou-wrapper .info-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.checkout-onepage-success .thankyou-wrapper .order-wrapper .column,
.checkout-onepage-success .thankyou-wrapper .info-wrapper .column {
  width: 50%;
  text-align: left;
}
.checkout-onepage-success .thankyou-wrapper .order-wrapper .column:last-child h4, .checkout-onepage-success .thankyou-wrapper .order-wrapper .column:last-child p,
.checkout-onepage-success .thankyou-wrapper .info-wrapper .column:last-child h4,
.checkout-onepage-success .thankyou-wrapper .info-wrapper .column:last-child p {
  padding-left: 10px;
}
.checkout-onepage-success .thankyou-wrapper .order-items .order-item-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
@media only screen and (max-width: 767px) {
  .checkout-onepage-success .thankyou-wrapper .order-items .order-item-row {
    margin-bottom: 15px;
  }
}
.checkout-onepage-success .thankyou-wrapper .order-items .order-item-row > .col {
  width: 110px;
}
.checkout-onepage-success .thankyou-wrapper .order-items .order-item-row > .col:last-child {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (max-width: 767px) {
  .checkout-onepage-success .thankyou-wrapper .order-items .order-item-row > .col:last-child .innerCol {
    width: 100%;
    padding-left: 20px;
  }
}
.checkout-onepage-success .thankyou-wrapper .order-items .order-item-row > .col:last-child .innerCol:first-child {
  flex: 1;
  text-align: left;
  padding-left: 20px;
}
.checkout-onepage-success .thankyou-wrapper .order-items .order-item-row > .col:last-child .innerCol:first-child .col.sku {
  margin-top: 5px;
}
.checkout-onepage-success .thankyou-wrapper .order-items .order-item-row > .col:last-child .innerCol:first-child .col.qty {
  margin-top: 5px;
  text-align: left !important;
}
@media only screen and (max-width: 767px) {
  .checkout-onepage-success .thankyou-wrapper .order-info .product-grid {
    margin-top: 30px;
  }
}
.checkout-onepage-success .thankyou-wrapper .order-info .product-grid .actions-toolbar {
  display: none;
}
.checkout-onepage-success .thankyou-wrapper .order-info .product-grid .actions-toolbar .action.primary {
  padding: 15px;
}
@media only screen and (max-width: 767px) {
  .checkout-onepage-success .thankyou-wrapper .order-info .product-grid .actions-toolbar {
    display: block;
  }
}
.checkout-onepage-success .thankyou-wrapper .order-info .product-grid .order-items .table-order-items tfoot tr th.textalign-right {
  text-align: left !important;
}
.checkout-onepage-success .thankyou-wrapper .order-info .product-grid .order-items .table-order-items tfoot tr.grand_total {
  font-size: 20px;
}