.theme-pearl.store-view-default.cms-page-view{
  .columns{
    @media only screen and (min-width: 768px) {
      max-width: 1800px !important;
    }
  }
  .page-title-wrapper{
    max-width: 1800px !important;
  }
  .page-wrapper{
    .breadcrumbs{
      max-width: 1820px !important;
      margin: 0 auto;
    }
  }
}
