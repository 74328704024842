.header{
  .quickcart-wrapper{
    .product-image-container{
      width: auto !important;
    }
    .notice{
      text-transform: none;
      color: red;
      float: left;
    }
  }
}

.cart-container{
  .cart{
    &.item{
      .notice{
        color: red;
        font-size: 12px;
        td{
          padding: 0 0 10px 0 !important;
        }
      }
    }
  }
}
