.catalog-category-view{
  &.page-layout-weltlayout{
    #layer-product-list{
      padding: 0;
    }
    .category-image{
      display: none;
    }
  }
  .category-view{
    max-width: 1800px;
    margin:0 auto;
    @media only screen and (max-width: 767px) {
      padding:0;
    }
    .category-image{
      img{
        width: 100%;
      }
    }
    .breadcrumbs{
      padding-left: 0 !important;
      padding-right: 0 !important;
      .items{
        &:after{
          content:'';
          display: block;
          clear:both;
        }
      }
    }
  }
  #layered-horizontal-container,#layer-product-list{
    @media only screen and (min-width: 768px){
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  #layered-horizontal-container{
    margin-bottom: 0;
    padding:0;
    #layered-filter-block{
      margin-bottom: 0;
      .filter-options-title{
        border:0;
      }
    }
  }
  .sidebar{
    display: none;
  }
}
