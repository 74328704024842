#layered-filter-block-container{
  .filter-content {
    .filter-options{
      .layer-input-filter{
        display: none;
        &:checked ~ a:before{
          background: #000;
        }
      }
      .filter-options-item{
        .filter-options-content{
          .items{
            .item{
              margin: 10px;
              a{
                width: auto;
                padding-left:0;
                margin:0;
                &:before{
                  margin: 4px 5px 0 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
