.header{
  .quickcart-wrapper{
    .block-quickcart{
      box-shadow: -4px 0px 20px -2px rgba(0,0,0,0.75);
      .block-content-quickcart{
        .quickcart-top{
          background: #254f9c;
        }
      }
    }
  }
}
.checkout-cart-index{
  .columns{
    padding:0 15px;
  }
  .cart-empty{
    a{
      color: #254f9c;
      text-decoration: underline;
    }
  }
}

.owl-carousel-products-upsell{
  .owl-thumbs{
    display: none;
  }
}

.page-main{
  @media only screen and (min-width: 767px){
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.cms-index-index{
  .page-main{
    @media only screen and (min-width: 767px){
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.toolbar{
  &.toolbar-products{
    padding: 10px 0;
    .toolbar-sorter.sorter{
      #sorter{
        margin-right:0;
      }
    }
  }
}

#layer-product-list{
  .toolbar{
    .pages{
      display: block !important;
    }
  }
  .search{
    .toolbar{
      .pages{
        display: block !important;
      }
    }
  }
}

.ias-load-prev,.ias-no-more{
  display: none !important;
}


#layer-product-list{
  > .toolbar:first-child{
    .pages{
      display: none !important;
    }
  }
  .search{
    > .toolbar:first-child{
      .pages{
        display: none !important;
      }
    }
  }
}

.theme-pearl.store-view-default.catalog-product-view{
  .page.messages{
    max-width: 1801px !important;
  }
}

#wpn-pearl-recaptcha-f77ddbc107973f97c243776865706675ebc87a42-container,
#recaptcha-caa20ac351216e6f76edced1efe7c61fff5f4796-container{
  visibility: hidden;
  opacity: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  margin:0;
  padding:0;
}

.page-wrapper .btt-button{
  @media only screen and (max-width: 767px){
    bottom: 15px !important;
  }
}

.copyright{
  background: #303030;
}

#layered-horizontal-container{
  border-bottom: 0 !important;
  #layered-filter-block{
    @media only screen and (min-width: 768px){
      border-bottom: 1px solid #ccc;
    }
  }
}

.slick-prev:before, .slick-next:before, .slick-prev:after, .slick-next:after,
.slick-prev:hover:before, .slick-next:hover:before{
  color: #fff;
}

