.account{
  .columns{
    padding-top:40px !important;
    @media only screen and (min-width: 768px) {
      padding:0 15px;
    }
    .block-collapsible-nav{
      @media only screen and (min-width: 768px){
        margin-bottom:50px !important;
      }
    }
  }
  .sidebar-additional{
    .block-wishlist{
      display: none;
    }
  }
}
