.gridBanner{
  .gridBannerInner{
    margin-left: -25px;
    margin-right: -25px;
    ul{
      padding-left:0;
      list-style-type: none;
      display: flex;
      flex-wrap:wrap;
      li{
        width: calc(25% - 50px);
        margin: 25px;
        position: relative;
        @media only screen and (max-width: 1366px) {
          width: calc(33.3333% - 50px);
        }
        @media only screen and (max-width: 991px) {
          width: calc(50% - 50px);
          .info{
            .gridtitle{
              font-size: 19px !important;
            }
            .btnLookBlue{
              font-size: 17px !important;
            }
          }
        }
        @media only screen and (max-width: 500px) {
          width: calc(100% - 50px);
        }
        .img{
          display: block;
          width: 100%;
          padding-bottom: 100%;
          background-size: 100%;
          background-position: center center;
          transition: .3s ease-in-out;
        }
        a{
          &:hover{
            .img{
              background-size: 120%;
            }
          }
        }
        .info{
          position: absolute;
          bottom:0;
          left:0;
          width: 100%;
          background: rgba(0,0,0,0.4);
          text-align: center;
          display: block;
          .gridtitle{
            color:#fff;
            font-weight: bold;
            font-size: 21px;
            text-transform: none;
            display: block;
            margin-bottom:3px;
          }
          .btnLookBlue{
            color:#fff;
            background: #283583;
            padding: 8px 20px;
            display: inline-block;
            margin-bottom: 5px;
            font-size: 19px;
            font-weight: bold;
          }
        }
      }
    }
  }
}
