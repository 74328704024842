.page-header{
  .panel.wrapper{
    display: none !important;
  }
  .header{
    > .row{
      @media only screen and (max-width: 768px){
        margin:0;
      }
    }
    &.content{
      @media only screen and (min-width: 1401px){
        padding-right: 15px !important;
        padding-left: 15px !important;
      }
    }
  }
}

@media only screen and (min-width: 768px){
  :root .theme-pearl .page-wrapper .page-header .logo img{
    height: auto;
    width: auto;
  }

  :root .page-header-v3 .navigation .level0 > .level-top, :root .page-header-v4 .navigation .level0 > .level-top{
    line-height: 44px;
  }
  //todo
}

.checkout-index-index{
  .page-wrapper{
    .page-header{
      .header{
        //.logo{
        //  float: left;
        //}
        .nav-sections{
          display: block;
          #ui-id-1{
            background: none !important;
          }
        }
      }
    }
  }
}

.page-wrapper{
  .page-header{
    background: none !important;
  }
}

.mobile-nav{
  .nav-sections {
    .nav-sections-items {
      #store\.links,#store\.menu{
        display: block !important;
        .header.links{
          display: block !important;
        }
      }
      .nav-sections-item-title{
        &.section-item-title{
          &:first-child{
            &:not(.active){
              & ~ #store\.menu{
                display: none !important;
              }
            }
          }
          &:nth-child(3){
            &:not(.active){
              & ~ #store\.links{
                display: none !important;
              }
            }
          }
          &:not(.active){
            @media only screen and (min-width:768px) and (max-width:991px){
              display: block !important;
            }
          }
          //&:not(.active) ~ .section-item-content{
          //  display: none !important;
          //}
        }
      }
    }
    .navigation{
      .level0{
        width: 100%;
      }
    }
  }
}

.customerLink{
  width: 35px;
  height: 35px;
  border: 1px solid #254f9c;
  border-radius: 50%;
  position: relative;
  margin:0 15px;
  padding: 0 16.5px;
  @media only screen and (max-width: 991px){
    display: none;
  }
  img{
    width: 20px;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
  }
}

.nav-sections{
  .navigatation{
    .level0{
      @media only screen and (min-width:768px) and (max-width:991px){
        width: 100%;
      }
    }
  }
  .customer-welcome + .authorization-link{
    @media only screen and (max-width: 991px) {
      display: block;
    }
  }
}


.customHeader{
  .stickyPlaceholder{
    height: 0;
  }
  .row{
    padding: 0 15px;
  }
  .topHeader{
    .topHeaderWrap{
      display: flex;
      position: relative;
      padding: 20px 0;
      align-items: center;
      z-index: 99;
    }
    .logoWrap{
      width: 300px;
      .logo{
        max-width: 100% !important;
        img{
          @media only screen and (max-width:767px){
            width: 115px !important;
            height: auto !important;

          }
        }
      }
      @media only screen and (max-width:767px){
        .logo{
          display: block;
          margin: 0 !important;
        }
      }
      .nav-toggle{
        position: absolute !important;
        right: 0;
        left: auto;
        top:45px !important;
        @media only screen and (max-width:991px){
          display: block;
        }
        @media only screen and (max-width:767px){
          top: 19px !important;
        }
        &:before{
          color: #254f9c;
        }
        //transform: translateY(-50%) !important;
      }
      img{
        transition: .3s ease-in-out;
      }
      .logo{
        float:none;
        margin: 0;
        display: block;
      }
    }
    .infoColumn{
      flex:1;
      .convLinks{
        display: flex;
        justify-content: flex-end;
        margin-bottom:20px;
        @media only screen and (max-width: 991px){
          display: none;
        }
        a{
          margin:0 20px;
          position: relative;
          color:#254f9c;
          text-transform: none;
          font-size: 18px;
          @media only screen and (max-width: 1200px){
            font-size: 15px;
          }
          &:first-child{
            font-weight:bold;
          }
          &:last-child{
            margin-right:0;
            &:after{
              display: none;
            }
          }
          &:after{
            content: '';
            position: absolute;
            right: -20px;
            top: 50%;
            transform: translateY(-50%);
            display: block;
            width: 1px;
            height: 15px;
            background: #254f9c;
          }
        }
      }
      > .actions{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top:20px;
        @media only screen and (max-width: 991px){
          margin-top: 0;
        }
        .block-search{
          margin-right:35px;
          width: 100%;
          max-width: 560px;
          @media only screen and (min-width: 767px) and (max-width: 991px){
            width: 250px;
          }
          @media only screen and (max-width: 767px){
            width: auto;
            margin-right: 0;
            margin-top: 0;
          }
          .search{
            #search{
              left: -9999px;
              margin: 0;
            }
            @media only screen and (max-width: 767px){
              position: relative;
              label.label{
                display: block;
                width: 35px;
                height: 35px;
                padding: 2px;
                border: 1px solid #254f9c;
                border-radius: 50%;
                position: relative;
                margin-right:15px;
                @media only screen and (max-width:450px){
                  margin-right:10px;
                }
                &:before{
                  width: 23px;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%,-50%);
                  margin: 0 !important;
                  color: #254f9c;
                }
                &.active ~ .control{
                  border: 1px solid #ccc !important;
                }
              }
              .control{
                position: absolute !important;
                top: 37px !important;
                right: 0;
                width: 280px !important;
                left: auto !important;
              }
            }
          }
          #search_mini_form{
            width: 100%;
            #searchautocomplete{
              width: 100% !important;
              .container-autocomplete{
                .product-list{
                  li{
                    padding-bottom: 0;
                    margin-bottom: 5px;
                  }
                }
              }
            }
          }
          #search{
            border:0;
            background:#ddd;
            padding:8px 12px !important;
            font-size:16px;
            max-width: 100%;
            width: 100%;
            height: 37px;
            &::placeholder,&::-webkit-input-placeholder,&::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
              color: #7E7E7E !important;
              opacity: 1; /* Firefox */
              font-size:16px;
            }
          }
          button.search{
            &:before{
              color:#254f9c
            }
          }
        }
        .storeWrap{
          display: flex;
          a{
            display: inline-flex;
            align-items: center;
            @media only screen and (max-width: 991px){
              display: block;
            }
            span{
              text-transform: none;
              color: #254f9c;
              font-size: 18px;
              @media only screen and (max-width: 991px){
                display: none;
              }
            }
          }
          .storeWrapInner{
            display: block;
            width: 35px;
            height: 35px;
            padding: 2px;
            border: 1px solid #254f9c;
            border-radius: 50%;
            position: relative;
            margin-right:10px;
            @media only screen and (max-width:450px){
              margin-right:7px;
            }
            img{
              width: 23px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%,-50%);
            }
          }
        }
        .minicart-wrapper{
          @media only screen and (max-width:450px){
            padding-left:0;
          }
          a.showcart{
            display: block;
            width: 35px;
            height: 35px;
            padding: 2px;
            border: 1px solid #254f9c;
            border-radius: 50%;
            position: relative;
            margin-right: 84px;
            top: 0;
            @media only screen and (max-width: 991px){
              margin-right: 48px;
            }
            @media only screen and (max-width:450px){
              margin-right:39px;
            }
            .text{
              width: auto;
              height:auto;
              position: relative;
              padding-left: 45px;
              line-height: 35px;
              color: #254f9c;
              font-size: 18px;
              text-transform: none;
              @media only screen and (max-width: 991px){
                display: none;
              }
            }
            &:before{
              content:'';
              background:url('../images/shopping-basket.svg');
              background-position: center center;
              width: 24px;
              height:24px;
              position: absolute;
              top:50%;
              left:50%;
              transform: translate(-50%,-50%);
            }
            .counter.qty{
              position: absolute;
              left: 20px;
              top: -12px;
              border-radius: 50%;
              border-color: #254f9c;
              background: #254f9c;
              @media only screen and (max-width: 767px) {
                min-width: 20px;
                height: 20px;
                line-height: 18px;
                padding: 0;
              }
              .counter-number{
                color:#fff;
              }
            }
          }
        }
        .header.links{
          display: none;
        }
        .minicart-wrapper{
          padding-left:0;
          margin-left:0;
          @media only screen and (max-width: 767px){
            .product{
              .actions{
                float: right;
                margin: -24px 0 0;
              }
            }
          }
        }
      }
    }
  }
  .menuHeader{
    background:#254f9c;
    margin-left: -15px;
    margin-right: -15px;
    position: relative;
    .nav-sections{
      float:none;
      .navigation{
        background:transparent;
        max-width: none;
        .level0{
          > .level-top{
            color:#fff;
            &:after{
              content: '';
              position: absolute;
              right: -15px;
              top: 0;
              display: block;
              width: 1px;
              height: 100%;
              background: #fff;
            }
          }
          .level0{
            &.submenu{
              box-shadow: 0 6px 12px rgba(0,0,0,.175);
              width: 100%;
              left:0 !important;
              max-width: 1800px;
              > li.submenu-child{
                display: flex;
                flex-wrap: wrap;
                > ul{
                  display: block !important;
                  width: 33.333% !important;
                }
              }
            }
          }
        }
        > ul{
          background:transparent;
          display: flex;
          flex-wrap:wrap;
          > li.level0{
            padding: 0 15px;
            margin:0;
            transition: 0.3s ease-in-out;
            a{
              transition: .3s ease-in-out;
            }
            .level-top{
              padding: 0;
            }
          }
        }
        ul{
          li{
            &.megamenu.level1:not(.parent){
              &:hover{
                > a:hover{
                  color: #000 !important;
                }
              }
            }
          }
        }
      }
    }
  }
  .headerServices{
    margin-left: -15px;
    margin-right: -15px;
    .headerServicesInner{
      display: flex;
      .serviceItem{
        border:1px solid #ccc;
        flex: 1;
        text-align: center;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        a{
          transition: 0.3s ease-in-out;
          &:hover{
            color: #254f9c;
          }
        }
        span{
          display: block;
          width: 100%;
        }
        &:nth-child(2){
          border-left:0;
          border-right:0;
        }
      }
    }
  }
  &.sticky{
    .stickyPlaceholder{
      height: 125px;
    }
    .topHeader{
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1000;
      background: #fff;
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
      .topHeaderWrap{
        padding:10px 15px;
        max-width: 1831px;
        margin:0 auto;
      }
      .logoWrap{
        width: 200px;
        .logo{
          img{
            width: auto;
          }
        }
        .nav-toggle{
          top: 20px !important;
          right: 15px;
          @media only screen and (max-width:767px){
            top: 9px !important;
          }
        }
      }
    }
    .infoColumn{
      .convLinks{
        display: none;
      }
      > .actions{
        margin-top:0;
      }
    }
  }
}
